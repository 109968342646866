<template>
  <div class="full-width">
    <document-toolbar
      v-if="!readonly"
      v-on="$listeners"
      no-history
      :delete-document="deleteEscalationProtocol"
      :revert="refreshDocument"
      :save="saveDocument"
      :show-cancel="!documentIsNew"
      :show-delete="!documentIsNew"
      :special-actions="actions"
      :tab="tab"
    >
    </document-toolbar>
    <document-in-tab>
      <cot-form
        :readonly="readonly"
        ref="form"
        :value="tab.canSave"
      >
        <cot-form
          ref="headerForm"
          v-model="headerForm"
          @input="validateMainForm"
        >
          <grid
            class="align-center"
            item-min-width="350px"
          >
            <translations
              :is-new="documentIsNew"
              :label="$t('t.Name')"
              :rules="nameRules"
              :translations.sync="computedName"
              :readonly="readonly"
              class="mr-4"
            />
            <translations
              :is-new="documentIsNew"
              :label="$t('t.Description')"
              :translations.sync="computedDescription"
              :readonly="readonly"
            />
            <v-checkbox
              :label="$t('t.Active')"
              class="ma-0"
              hide-details
              v-model="computedIsActive"
              :readonly="readonly"
            />
          </grid>
          <column-picker
            :attach="false"
            :clearable="true"
            :label="$t('t.AppliesTo')"
            :filters="{ 'document-types': cameleonAccountDocTypes}"
            :readonly="readonly"
            :selected-col-id.sync="computedApplyToCol"
            consolidation-disabled
            bool-cols-only
            class="applyTo my-4"
          >
            <template
              v-slot:prepend-audit
              v-if="readonly"
            >
              <audit-bool-icon
                class="mr-2"
                :value="computedAuditProtocol"
              />
            </template>
          </column-picker>
        </cot-form>
        <levels
          class="mt-4"
          v-if="computedLevels"
          :document.sync="computedLevels"
          @duplicate-level="onDuplicateLevel"
          @remove-level="onRemoveLevel($event)"
          :default-level="getDefaultLevel"
          :readonly="readonly"
        >
          <template v-slot:level-header="{index}">
            <audit-bool-icon
              v-if="dataAuditResult"
              class="mr-2"
              :value="getComputedAuditLevel(index).conds"
            />
            <v-icon
              v-if="computedLevels[index] && !subForms[index]"
              class="mr-2"
              color="error"
            >
              {{$icon('i.Error')}}
            </v-icon>
            <span>{{displayLevelName(index)}}</span>
          </template>
          <template v-slot:level-header-infos="{index}">
            <template>
              <div class="mr-8">
                <span
                  class="mr-4"
                  v-if="computedLevels[index] && computedLevels[index].manualActivities"
                >
                  {{computedLevels[index].manualActivities.length}} {{$t("t.ManualActivities")}}
                </span>
                <span v-if="computedLevels[index] && computedLevels[index].automatedActivities">
                  {{computedLevels[index].automatedActivities.length}} {{$t("t.AutomatedActivities")}}
                </span>
              </div>
            </template>
          </template>
          <template v-slot:level-content="{index}">
            <template v-if="computedLevels[index]">
              <cot-form
                v-model="subForms[index]"
                @input="validateMainForm"
              >
                <grid
                  item-min-width="310px"
                  class="align-center"
                >
                  <translations
                    class="level-name"
                    :label="$t('t.Name')"
                    :is-new="true"
                    :rules="nameRules"
                    :translations.sync="computedLevels[index].name"
                    :readonly="readonly"
                  />
                  <v-checkbox
                    :label="$t('t.WaitForProtocolTasksToClose')"
                    v-model="computedLevels[index].waitForWorkItemsToBeDone"
                    :readonly="readonly"
                  />
                </grid>
                <grid
                  item-min-width="250px"
                  class="align-center"
                >
                  <column-picker
                    :attach="false"
                    :clearable="true"
                    :label="$t('t.PositiveTriggers')"
                    :filters="{ 'document-types': cameleonAccountDocTypes}"
                    :readonly="readonly"
                    :selected-col-id.sync="computedLevels[index].triggerColumnId"
                    consolidation-disabled
                    bool-cols-only
                  >
                    <template
                      v-slot:prepend-audit
                      v-if="dataAuditResult"
                    >
                      <audit-bool-icon
                        class="mr-2"
                        :value="getComputedAuditLevel(index).conds"
                      />
                    </template>
                  </column-picker>
                  <number-field
                    :number.sync="computedLevels[index].minimumNumberOfDays"
                    :label="$t('t.MinimumNumberOfDays')"
                    :rules="daysRules"
                    :readonly="readonly"
                  />
                  <number-field
                    :number.sync="computedLevels[index].numberOfDaysBeforeRepetition"
                    :label="$t('t.NumberOfDaysBeforeRepetition')"
                    :rules="daysRules"
                    :readonly="readonly"
                  />
                </grid>
                <audit-generate-items
                  :audit="dataAuditResult"
                  :audit-level="getComputedAuditLevel(index)"
                />
                <div>
                  <v-card class="ma-0">
                    <v-card-title>
                      <div class="d-flex align-center">
                        {{computedLevels[index].manualActivities.length}} {{$t("t.ManualActivities")}}
                        <v-btn
                          @click="addManualActivitiesToLevel(index)"
                          class="ml-4"
                          color="primary"
                          fab
                          v-if="!readonly"
                          x-small
                        >
                          <v-icon>{{$icon('i.Plus')}}</v-icon>
                        </v-btn>
                      </div>
                    </v-card-title>
                    <v-card-text>
                      <grid
                        v-for="(activity, i) in computedLevels[index].manualActivities"
                        :key="activity.index"
                        item-min-width="250px"
                        class="align-center"
                      >
                        <column-picker
                          :attach="false"
                          :clearable="true"
                          :label="$t('t.Conditions')"
                          :filters="{ 'document-types': cameleonAccountDocTypes}"
                          :readonly="readonly"
                          :selected-col-id.sync="computedLevels[index].manualActivities[i].conditionColumnId"
                          consolidation-disabled
                          bool-cols-only
                        >
                          <template v-slot:prepend-audit>
                            <audit-bool-icon
                              class="mr-2"
                              v-if="dataAuditResult"
                              :value="getComputedAuditLevelManualActivity(index, computedLevels[index].manualActivities[i].id)"
                            />
                          </template>
                        </column-picker>
                        <picker
                          :rules="required"
                          :clearable="false"
                          document-types="work-item-types"
                          :attach="false"
                          :label="$t('t.ActivityType')"
                          :selected-id.sync="computedLevels[index].manualActivities[i].workItemTypeId"
                          :readonly="readonly"
                          work-item-type-is-account-action
                        />
                        <picker
                          :clearable="false"
                          document-types="work-item-priorities"
                          :attach="false"
                          :label="$t('t.WorkItemPriorities')"
                          :selected-id.sync="computedLevels[index].manualActivities[i].priority"
                          :readonly="readonly"
                        />
                        <picker
                          :rules="required"
                          document-types="groups"
                          :attach="false"
                          :label="$t('t.PerformedBy')"
                          :selected-id.sync="computedLevels[index].manualActivities[i].groupId"
                          :filters="{ 'roles': rolesFilters}"
                          :readonly="readonly"
                        />
                        <number-field
                          :number.sync="computedLevels[index].manualActivities[i].timeoutDays"
                          :label="$t('t.TimeoutDays')"
                          :rules="daysRules"
                          :readonly="readonly"
                        />
                        <v-btn
                          class="mr-4"
                          fab
                          x-small
                          @click="removeManualActivity(index, i)"
                          v-if="!readonly"
                        >
                          <v-icon>{{$icon('i.Delete')}}</v-icon>
                        </v-btn>
                      </grid>
                    </v-card-text>
                  </v-card>
                  <v-card class="ma-0 mt-4 mb-2">
                    <v-card-title>
                      <div class="d-flex align-center">
                        {{computedLevels[index].automatedActivities.length}} {{$t("t.AutomatedActivities")}}

                        <v-btn
                          @click="addAutomatedActivitiesToLevel(index)"
                          class="ml-4"
                          color="primary"
                          fab
                          x-small
                          v-if="!readonly"
                        >
                          <v-icon>{{$icon('i.Plus')}}</v-icon>
                        </v-btn>
                      </div>
                    </v-card-title>
                    <v-card-text>
                      <grid
                        v-for="(activity, i) in computedLevels[index].automatedActivities"
                        :key="activity.index"
                        item-min-width="250px"
                        class="align-center"
                      >
                        <column-picker
                          :attach="false"
                          :clearable="true"
                          :label="$t('t.Conditions')"
                          :filters="{ 'document-types': cameleonAccountDocTypes}"
                          :readonly="readonly"
                          :selected-col-id.sync="computedLevels[index].automatedActivities[i].conditionColumnId"
                          consolidation-disabled
                          bool-cols-only
                        >
                          <template v-slot:prepend-audit>
                            <audit-bool-icon
                              class="mr-2"
                              v-if="dataAuditResult"
                              :value="getComputedAuditLevelAutomatedActivity(index, computedLevels[index].automatedActivities[i].id)"
                            />
                          </template>

                        </column-picker>
                        <picker
                          :clearable="false"
                          document-types="reminder-types"
                          :attach="false"
                          :rules="required"
                          :label="$t('t.ActivityType')"
                          :selected-id.sync="computedLevels[index].automatedActivities[i].reminderType"
                          :readonly="readonly"
                          @change="automatedActivityReminderTypeChange(computedLevels[index].automatedActivities[i])"
                        />
                        <picker
                          :disabled="!computedLevels[index].automatedActivities[i].reminderType"
                          :clearable="false"
                          :rules="required"
                          document-types="templates"
                          :attach="false"
                          :label="$t('t.Template')"
                          :selected-id.sync="computedLevels[index].automatedActivities[i].templateId"
                          :filters="remindersFilters(computedLevels[index].automatedActivities[i])"
                          :readonly="readonly"
                        />
                        <v-select
                          v-if="computedLevels[index].automatedActivities[i].reminderType !== 'ar24' && computedLevels[index].automatedActivities[i].reminderType != 'maileva'"
                          :rules="requiredContactGroup"
                          :clearable="false"
                          :label="$t('t.ContactGroups')"
                          :items="contactGroups"
                          item-text="name"
                          :item-value="contactGroupsItemValue"
                          :value="computedLevels[index].automatedActivities[i]"
                          :readonly="readonly"
                          @input="setContactGroup(index, i, $event)"
                        />
                        <v-checkbox
                          :label="$t('t.SendToParentAccount')"
                          v-model="computedLevels[index].automatedActivities[i].sendToParentAccount"
                          :readonly="readonly"
                        />
                        <v-btn
                          class="mr-4"
                          fab
                          x-small
                          @click="removeAutomatedActivity(index, i)"
                          v-if="!readonly"
                        >
                          <v-icon>{{$icon('i.Delete')}}</v-icon>
                        </v-btn>
                      </grid>
                    </v-card-text>
                  </v-card>
                </div>
              </cot-form>
            </template>
          </template>
        </levels>
      </cot-form>
    </document-in-tab>
  </div>
</template>

<script>
import Search from '@/pages/search/controllers/search'
import DocEdit from '@/mixins/document-editor'
import auditController from '@/auditController'
import IncExc from '@/pages/search/controllers/inc-exc'

export default {
  mixins: [DocEdit],
  beforeDestroy () {
    this.search = undefined
  },
  components: {
    AuditBoolIcon: () => import('@/components/audit-bool-icon'),
    AuditGenerateItems: () => import('./audit/audit-generate-items'),
    ColumnPicker: () => import('@/components/column-picker'),
    CotForm: () => import('@/components/cot-form'),
    DocumentInTab: () => import('@/components/document-in-tab'),
    DocumentToolbar: () => import('@/components/document-toolbar'),
    Grid: () => import('@/components/grid'),
    Levels: () => import('@/components/levels'),
    NumberField: () => import('@/components/number-field'),
    Picker: () => import('@/components/picker'),
    Translations: () => import('@/components/translations')
  },
  computed: {
    actions () {
      const actions = []
      if (!this.documentIsNew) {
        actions.push({
          text: this.$t('t.Duplicate'),
          icon: this.$icon('i.Templates'),
          callback: this.duplicate
        })
      }
      return actions.length ? actions : undefined
    },
    readonly () {
      return this.auditController.dataAudit !== null || !this.$store.getters.currentUserHasAnyPermission(['CollectionStrategySettings', 'AccountCanDebugModifyProtocol'])
    },
    cacheType () {
      return DocEdit.CacheType.EscalationProtocolDetail
    },
    contactGroups () {
      return [
        ...(this.search?.items ?? []),
        { type: 'main-sales-rep', name: this.$t('t.MainSalesRep'), id: null },
        { type: 'second-sales-rep', name: this.$t('t.SecondSalesRep'), id: null }
      ].map(i => ({
        contactType: i.type,
        contactGroupId: i.id,
        name: i.name
      }))
    },
    cameleonAccountDocTypes () {
      const cameleonDocTypes = new IncExc()
      cameleonDocTypes.include(['accounts'])
      return cameleonDocTypes
    },
    computedApplyToCol: {
      get () { return this.document?.applyToColumnId },
      set (v) {
        this.document.applyToColumnId = v
      }
    },
    computedAuditLevels () {
      return this.dataAuditResult?.protocol?.levels
    },
    computedAuditProtocol () {
      return this.dataAuditResult?.protocol?.annotations?.conds
    },
    computedName: {
      get () { return this.document?.name || {} },
      set (v) {
        this.document.name = v
      }
    },
    computedDescription: {
      get () { return this.document?.description || {} },
      set (v) {
        this.document.description = v
      }
    },
    computedIsActive: {
      get () { return this.document?.isActive },
      set (v) {
        this.document.isActive = v
      }
    },
    computedLevels: {
      get () { return this.document?.levels },
      set (v) {
        this.document.levels = v
      }
    },
    rolesFilters () {
      const roles = new IncExc()
      roles.include(['sales-reps', 'division-supervisors', 'users', 'agent-supervisors'])
      return roles
    },
    nameRules () {
      const MIN = 1
      const MAX = 100
      return [
        v => !!v.length || this.$t('t.IsRequired'),
        v => (v.length >= MIN && v.length <= MAX) || this.$t('t.Length')
      ]
    },
    daysRules () {
      return [
        v => Number.isInteger(Number(v)) || this.$t('t.MustBeInteger'),
        v => v >= 0 || this.$t('t.MustBeGreaterOrEqualTo', { value: 0 }),
        v => v <= 92233720368547 || this.$t('t.MustBeLesserThan', { value: 92233720368547 })
      ]
    },
    id () {
      return this.tab.documentId
    }
  },
  data () {
    const search = new Search().chain(s => s.searchedDocumentTypes.include(['contact-groups']))
    search.execute()
    return {
      dataAuditResult: null,
      auditController: auditController,
      duplicationInprogress: false,
      headerForm: false,
      required: [v => (!!v && !this.lodash.isEqual(v, {}) && !this.lodash.isEqual(v, { id: null })) || this.$t('t.IsRequired')],
      requiredContactGroup: [v => (!!v?.contactGroupId || v?.contactType !== 'contact-groups') || this.$t('t.IsRequired')],
      search,
      subForms: [],
      dataDocumentEmit: this.$nextTickDedup(this.emitDocument),
      lastType: null
    }
  },
  methods: {
    duplicate () {
      this.cache.doc.id = null
      this.document.id = null
      this.document.name = undefined

      this.document.levels = this.lodash.cloneDeep(this.document?.levels ?? []).map(l => {
        l.id = null
        l.automatedActivities.forEach(a => { a.id = null })
        l.manualActivities.forEach(m => { m.id = null })
        return l
      })
    },
    getDefaultLevel () {
      return {
        automatedActivities: [],
        waitForWorkItemsToBeDone: false,
        minimumNumberOfDays: 0,
        numberOfDaysBeforeRepetition: 0,
        manualActivities: [],
        name: null
      }
    },
    addManualActivitiesToLevel (index) {
      this.document.levels[index].manualActivities.push({
        workItemTypeId: null,
        timeoutDays: 0,
        conditionColumnId: null,
        priority: 1,
        groupId: null
      })
    },
    addAutomatedActivitiesToLevel (index) {
      this.document.levels[index].automatedActivities.push({
        reminderType: null,
        templateId: null,
        contactGroupId: null,
        contactType: 'contact-groups',
        sendToParentAccount: false
      })
    },
    cancel () {
      this.refreshDocument()
    },
    contactGroupsItemValue (item) {
      return {
        contactType: item.contactType,
        contactGroupId: item.contactGroupId
      }
    },
    async deleteEscalationProtocol () {
      const apiUrl = '/core/v6/escalation/escalation-protocols'
      this.$http().delete(`${apiUrl}/${this.id}`).then(async () => {
        this.$router.push('/').catch(e => console.log(e))
      }).catch(e => {
        this.$store.dispatch(
          'showErrorSnackbar',
          e.response?.data?.message
        )
      })
    },
    displayLevelName (index) {
      return this.document?.levels?.[index]?.name?.[this.$store.getters.currentUser.culture] ||
        this.$t('t.Level')
    },
    emitDocument () {
      const doc = this.document

      if (!this.lodash.isEqual(doc, this.document)) {
        this.$emit('update:document', this.lodash.cloneDeep(doc))
      }
    },
    async getAudit () {
      this.dataAuditResult = null

      if (!this.auditController.dataAudit) {
        return
      }

      const apiUrl = '/core/v6/escalation/escalation-protocols'
      const x = {
        auditSource: this.auditController.dataAudit.source,
        view: this.auditController.dataAudit.view.id
      }

      const result = await this.$http().post(`${apiUrl}/${this.id}/audit`, x)
      this.dataAuditResult = result.data
    },
    getComputedAuditLevel (levelIndex) {
      return (this.computedAuditLevels
        ? this.computedAuditLevels[this.computedLevels[levelIndex].id]
        : null) || {}
    },
    getComputedAuditLevelManualActivity (levelIndex, activityId) {
      const a = this.getComputedAuditLevel(levelIndex)
      const r = 'workItemsTemplatesConds' in a ? a.workItemsTemplatesConds[activityId] : null
      return r
    },
    getComputedAuditLevelAutomatedActivity (levelIndex, activityId) {
      const a = this.getComputedAuditLevel(levelIndex)
      const r = 'automatedActivitiesConds' in a ? a.automatedActivitiesConds[activityId] : null
      return r
    },
    onDuplicateLevel (newLevel) {
      newLevel.id = null
    },
    onRemoveAction (index) {
      this.subForms.splice(index, 1)
      this.validateMainForm()
    },
    automatedActivityReminderTypeChange (automatedActivity) {
      const tt = automatedActivity?.reminderType === 'text-message' ? 'text-message-reminder' : 'reminder'

      if (automatedActivity.reminderType === 'ar24' || automatedActivity.reminderType === 'maileva') {
        automatedActivity.contactType = 'contact-groups'
      }

      if (this.lastType !== tt) {
        this.lastType = tt
        automatedActivity.templateId = null
      }
    },
    remindersFilters (automatedActivity) {
      const reminderType = automatedActivity?.reminderType === 'text-message' ? 'text-message-reminder' : 'reminder'
      return { 'template-types': [[reminderType], []] }
    },
    async refreshDocumentHook () {
      if (this.documentIsNew) {
        this.document = Object.assign(
          {},
          this.document,
          {
            name: this.document?.name || {},
            description: this.document?.description || {},
            levels: [],
            isActive: false
          }
        )
        this.dataDocumentEmit()
      }
      // else {
      //   this.$store.commit('setModule', {
      //     name: this.document?.name[this.$store.getters.currentUser.culture],
      //     icon: 'i.EscalationProtocols'
      //   })
      // }
    },
    removeAutomatedActivity (levelIdx, activityIdx) {
      this.document.levels[levelIdx].automatedActivities.splice(activityIdx, 1)
    },
    removeManualActivity (levelIdx, activityIdx) {
      this.document.levels[levelIdx].manualActivities.splice(activityIdx, 1)
    },
    setContactGroup (levelIndex, taskIndex, value) {
      this.document.levels[levelIndex].automatedActivities[taskIndex].contactType = value ? value.contactType : 'contact-groups'
      this.document.levels[levelIndex].automatedActivities[taskIndex].contactGroupId = value ? value?.contactGroupId : null
    },
    async save () {
      const apiUrl = '/core/v6/escalation/escalation-protocols'

      if (this.documentIsNew) {
        const response = await this.$http().post(apiUrl, this.document)
        this.$nextTick(() => {
          this.$router.push('/escalation-protocols/' + response.data?.id)
        })
        return response
      } else {
        return await this.$http().put(`${apiUrl}/${this.id}`, this.document)
      }
    },
    validateMainForm () {
      this.$nextTick(() => this.$emit('document-can-save', { tab: this.tab, value: Boolean(this.subForms.every(f => f) && this.$refs?.headerForm?.value) }))
    }
  },
  props: {
    tab: Object
  },
  watch: {
    'auditController.dataAudit': {
      immediate: true,
      handler (n, o) {
        if (n || o) {
          this.getAudit()
        }
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
.applyTo
  width 400px

.full-width
  width 100%
</style>
